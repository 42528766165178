<template>
  <footer class="footer">
    <div class="footer__content">
      <div class="footer__top vertical-margin">
        <ul class="footer__links">
          <li
            v-for="link in links"
            :key="link.title"
            class="footer__link-item">
            <a
              :href="link.href"
              target="_blank"
              class="footer__link">
              {{ link.title }}
            </a>
          </li>
        </ul>
        <ul class="footer__socials">
          <li
            v-for="social in socials"
            :key="social.icon"
            class="footer__social">
            <a
              :href="social.href"
              target="_blank"
              class="footer__social-link">
              <Icon
                :name="social.icon"
                size="l"></Icon>
            </a>
          </li>
        </ul>
      </div>
      <div class="footer__payment vertical-margin">
        <span>Приём платежей: &copy;&nbsp;ООО &laquo;Арена Плюс&raquo;</span>
        <span>ИНН 7606117641&nbsp;/</span>
        <span>КПП 760401001, 150000, г. Ярославль,</span>
        <span>ул. Некрасова, д.&nbsp;41, оф.&nbsp;20  |</span>
        <span>
          <a class="footer__link link-hover color-white-85" href="tel:+7 910 973-28-53">+7 910 973-28-53</a>  |  <a class="footer__link link-hover color-white-85" href="mailto:support@russiarunning.com">support@russiarunning.com</a>
        </span>
      </div>
      <div class="footer__service vertical-margin">
        <span>Оказание услуг: ООО &laquo;КГ&nbsp;Событие&raquo;</span>
        <span>ИНН 7717582845&nbsp;/</span>
        <span>КПП 771701001, 129226, г. Москва,</span>
        <span>ул. Сельскохозяйственная, д.&nbsp;11, к.&nbsp;3,</span>
        <span>эт.&nbsp;1, пом. II, оф.&nbsp;22  |</span>
        <span>
          <a class="footer__link link-hover color-white-85" href="tel:+7 495 741-36-01">+7 495 741-36-01</a>  |  <a class="footer__link link-hover color-white-85" href="mailto:info@so-bytie.ru">info@so-bytie.ru</a>
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
import Icon from '@rr-component-library/icon/src/main';

export default {
  name: 'Footer',
  data() {
    return {
      year: new Date().getFullYear(),
      components: {
        Icon,
      },
      socials: [
        {
          icon: 'vkontakte',
          href: 'https://vk.com/rzdrun',
        },
        {
          icon: 'youtube',
          href: 'https://www.youtube.com/watch?v=mHUIIW_xCt4&feature=youtu.be',
        },
      ],
      links: [
        {
          title: 'Политика конфиденциальности',
          href: 'https://events.rzdrun.ru/Content/files/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%B2%20%D0%BE%D1%82%D0%BD%D0%BE%D1%88%D0%B5%D0%BD%D0%B8%D0%B8%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.pdf',
        },
        {
          title: 'Договор-оферта',
          href: 'https://events.rzdrun.ru/Content/files/%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80_%D0%BE%D1%84%D0%B5%D1%80%D1%82%D0%B0.pdf',
        },
        {
          title: 'Условия оплаты',
          href: 'https://events.rzdrun.ru/Content/files/rules-conditions-pay.pdf',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import './Footer';
</style>
